/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {Text, View, StyleSheet, TouchableOpacity, Modal} from 'react-native';

import Colors from '../styles/colors';

const Confirm = ({dimension, idDelete, Loading, msg, Goback, OnFunction}) => {
  return (
    <Modal transparent={true}>
      <View style={{height: dimension.width > 500 ? 100 : '15vw'}} />
      <View style={styles.promptConfirm}>
        <View style={styles.promptConfirmPrompt}>
          <Text style={styles.promptConfirmTitle}>Atenção</Text>
          <Text style={styles.promptConfirmText}>{msg}</Text>
          <View style={styles.viewButton}>
            <TouchableOpacity
              style={styles.imputButton}
              onPress={() => {
                Goback(false);
                Loading(false);
              }}>
              <Text style={{color: 'white', fontSize: 12}}>NÃO</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.imputButton}
              onPress={() => {
                Loading(true);
                OnFunction(idDelete);
                Goback(false);
              }}>
              <Text style={{color: 'white', fontSize: 12}}>SIM</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  promptConfirm: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  promptConfirmPrompt: {
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 20,
    marginHorizontal: 30,
    padding: 15,
  },
  promptConfirmTitle: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  promptConfirmText: {
    marginBottom: 10,
  },
  viewButton: {
    flexDirection: 'row',
    alignContent: 'center',
    marginTop: 10,
  },
  imputButton: {
    backgroundColor: Colors.contra,
    paddingVertical: 2,
    paddingHorizontal: 15,
    borderRadius: 8,
    margin: 5,
  },
});
export default Confirm;
