/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
import React, {useState, useEffect} from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  Image,
} from 'react-native';

import Homepage from '../../../services/homepage';
import Confirm from '../../../services/confirm';
import Colors from '../../../styles/colors';

const Client = ({
  Loading,
  Goback,
  dimension,
  recover,
  recoverClient,
  recoverOff,
  alterCliente,
  change,
  OnLogout,
  setAlterCliente,
}) => {
  const [errorMensage, setErrorMensage] = useState('');
  const [erro, setErro] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [msgSucess, setMsgSucess] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [nameS, setNameS] = useState('');
  const [client, setClient] = useState([{}]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (recover && recoverClient) {
      setAlterCliente(true);
      RecoverOff();
    }
  }, [recover, recoverClient]);

  useEffect(() => {
    if (alterCliente) {
      reset();
      GetClient();
    } else if (!alterCliente) {
      reset();
      Loading(false);
    } else {
      Loading(false);
    }
  }, [alterCliente]);

  const reset = () => {
    setClient([{}]);
    setNameS('');
    setLogin('');
    setPassword('');
    setConfirmPassword('');
    setEmail('');
    setConfirmEmail('');
    setSucess(false);
    setMsgSucess('');
    setErro(false);
    setErrorMensage('');
  };

  const GetClient = () => {
    fetch(Homepage + 'getClient.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.error === '') {
          setClient(data);
          Loading(false);
        } else {
          setErro(true);
          setErrorMensage(data.error);
          console.error(data.error);
          OnLogout();
          Loading(false);
        }
      })
      .catch((error) => {
        setErro(true);
        setErrorMensage('erro ao buscar dados internos');
        console.error('erro ao buscar dados internos', error);
        OnLogout();
        Loading(false);
      });
  };
  const RecoverOff = () => {
    fetch(Homepage + 'recoverOff.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Id: recoverClient,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.recoverOff) {
          console.warn('Sistema de recuperação concluido!');
          GetClient();
          recoverOff(false);
        } else {
          console.warn('Erro ao finalizar recuperação');
          Loading(false);
        }
      })
      .catch((error) => {
        console.error(
          'Recuperação de senha: erro ao buscar dados internos',
          error,
        );
        Loading(false);
      });
  };

  const OnRegisterClient = () => {
    fetch(Homepage + 'registerClient.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Id: client.id ? client.id : null,
        Name: nameS ? nameS : null,
        Login: login ? login : null,
        Password: password ? password : null,
        Email: email ? email : null,
        AlterClient: alterCliente,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          //sucesso
          reset();
          //mudar o master
          change();
          setErro(false);
          setSucess(data.status);
          setMsgSucess(data.mesage);

          if (alterCliente) {
            GetClient();
          } else {
            Loading(false);
          }
        } else {
          setSucess(false);
          setErro(true);
          setErrorMensage(data.mesage);
          Loading(false);
        }
      })
      .catch((error) => {
        setErro(true);
        setErrorMensage('Erro ao Registrar no banco de dados');
        console.error('Erro ao Registrar no banco de dados', error);
        Loading(false);
      });
  };
  const onDeleteClient = () => {
    fetch(Homepage + 'deleteClient.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Id: client.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.delete) {
          console.warn('Usuário deletado com sucesso!');
          OnLogout();
        } else {
          setErro(true);
          setErrorMensage('Erro ao deletar Usuário');
          console.warn('Erro ao deletar Usuário');
          Loading(false);
        }
      })
      .catch((error) => {
        console.error(
          'erro ao buscar dados internos para deletar o usuário',
          error,
        );
        setErro(true);
        setErrorMensage('erro ao buscar dados internos para deletar o usuário');
        Loading(false);
      });
  };

  const responsive =
    dimension.width > 600 ? styles.ContainerButton : styles.ContainerButton1;
  return (
    <View style={styles.container}>
      <View style={styles.preScroll} />
      <ScrollView>
        <View style={styles.scrollLogin}>
          <Text style={styles.titleLogin}>CADASTRO DE USUÁRIO</Text>
          <View style={styles.masterLogin}>
            <View style={styles.login}>
              {erro && (
                <View
                  style={
                    dimension.width > 600
                      ? styles.loginInvalid1
                      : styles.loginInvalid2
                  }>
                  <Text style={{color: 'white', fontSize: 12}}>
                    {errorMensage}
                  </Text>
                </View>
              )}
              {sucess && (
                <View
                  style={
                    dimension.width > 500 ? styles.sucess1 : styles.sucess2
                  }>
                  <Text style={{color: 'white', fontSize: 12}}>
                    {msgSucess}
                  </Text>
                </View>
              )}
              <View style={responsive}>
                <View>
                  <Text style={styles.textinput}>NOME E SOBRENOME</Text>
                  <TextInput
                    onChangeText={(text) => setNameS(text)}
                    value={nameS}
                    placeholder={client.name ? client.name : ''}
                    style={styles.input}
                  />
                </View>
                <View>
                  <Text style={styles.textinput}>LOGIN</Text>
                  <TextInput
                    onChangeText={(text) => setLogin(text)}
                    value={login}
                    placeholder={client.login ? client.login : ''}
                    style={styles.input}
                  />
                </View>
              </View>
              <View style={responsive}>
                <View>
                  <Text style={styles.textinput}>SENHA</Text>
                  <TextInput
                    onChangeText={(text) => setPassword(text)}
                    value={password}
                    placeholder={alterCliente && client.login ? '******' : ''}
                    style={styles.input}
                    secureTextEntry={true}
                  />
                </View>
                <View>
                  <Text style={styles.textinput}>CONFIRMA SENHA</Text>
                  <TextInput
                    onChangeText={(text) => setConfirmPassword(text)}
                    value={confirmPassword}
                    placeholder={alterCliente && client.login ? '******' : ''}
                    style={styles.input}
                    secureTextEntry={true}
                  />
                </View>
              </View>
              <View style={responsive}>
                <View>
                  <Text style={styles.textinput}>E-MAIL</Text>
                  <TextInput
                    onChangeText={(text) => setEmail(text)}
                    value={email}
                    placeholder={client.email ? client.email : ''}
                    style={styles.input}
                  />
                </View>
                <View>
                  <Text style={styles.textinput}>CONFIRMA E-MAIL</Text>
                  <TextInput
                    onChangeText={(text) => setConfirmEmail(text)}
                    value={confirmEmail}
                    placeholder={client.email ? client.email : ''}
                    style={styles.input}
                  />
                </View>
              </View>
            </View>
            <View style={styles.ContainerButtonButtom}>
              <TouchableOpacity
                style={styles.imputButton}
                onPress={() => {
                  Loading(true);
                  Goback('client');
                }}>
                <Text style={{color: 'white', fontSize: 18}}>Cancelar</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.imputButton}
                onPress={() => {
                  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (
                    ((!nameS ||
                      !login ||
                      !password ||
                      !confirmPassword ||
                      !email ||
                      !confirmEmail) &&
                      !alterCliente) ||
                    (!nameS &&
                      !login &&
                      !password &&
                      !confirmPassword &&
                      !email &&
                      !confirmEmail)
                  ) {
                    setSucess(false);
                    setErro(true);
                    setErrorMensage('Você precisa preencher todos os campos');
                  } else if (password !== confirmPassword) {
                    setSucess(false);
                    setErro(true);
                    setErrorMensage('A senha não confere com confirma senha');
                  } else if (email !== confirmEmail) {
                    setSucess(false);
                    setErro(true);
                    setErrorMensage('O e-mail não confere com confirma e-mail');
                  } else if (
                    regex.test(email) === false &&
                    email.length !== 0
                  ) {
                    setSucess(false);
                    setErro(true);
                    setErrorMensage('O e-mail não é válido, tente novamente');
                  } else if (nameS.length > 25) {
                    setSucess(false);
                    setErro(true);
                    setErrorMensage(
                      'O nome excedeu o máximo de caractere permetido (25)',
                    );
                  } else if (login.length > 20) {
                    setSucess(false);
                    setErro(true);
                    setErrorMensage(
                      'O login excedeu o máximo de caractere permetido (20)',
                    );
                  } else if (nameS.length < 4 && nameS.length !== 0) {
                    setSucess(false);
                    setErro(true);
                    setErrorMensage('O nome deve ter no mínimo 4 caracteres');
                  } else if (login.length < 4 && login.length !== 0) {
                    setSucess(false);
                    setErro(true);
                    setErrorMensage('O login deve ter no mínimo 4 caracteres');
                  } else if (password.length < 4 && password.length !== 0) {
                    setSucess(false);
                    setErro(true);
                    setErrorMensage('A senha deve ter no mínimo 4 caracteres');
                  } else {
                    setErro(false);
                    Loading(true);
                    OnRegisterClient();
                  }
                }}>
                <Text style={{color: 'white', fontSize: 18}}>OK</Text>
              </TouchableOpacity>
            </View>
            {client.login && (
              <TouchableOpacity
                onPress={() => {
                  setConfirmDelete(true);
                }}>
                <Image
                  accessibilityLabel="React logo"
                  source={{
                    uri: 'https://bazonicell.com.br/images/svg/deleteRed.svg',
                  }}
                  style={styles.deleteClient}
                  resizeMode="contain"
                />{' '}
              </TouchableOpacity>
            )}
            {confirmDelete && (
              <Confirm
                dimension={dimension}
                Loading={Loading}
                msg="Tem certeza que deseja apagar esse usuário? Essa medida é irreversível e irá te deslogar do sistema."
                Goback={setConfirmDelete}
                OnFunction={onDeleteClient}
              />
            )}
          </View>
        </View>
      </ScrollView>
      <View style={styles.preScroll} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  preScroll: {
    flex: 1,
  },
  scrollLogin: {
    padding: 20,
    textAlign: 'center',
  },
  masterLogin: {
    marginTop: 10,
    alignSelf: 'center',
    backgroundColor: Colors.white,
    paddingHorizontal: 30,
    paddingVertical: 15,
    borderRadius: 20,
  },
  login: {
    alignItems: 'center',
  },
  titleLogin: {
    fontWeight: 'bold',
    fontSize: 25,
    marginBottom: 20,
    color: Colors.black,
  },
  textinput: {
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 18,
  },
  input: {
    borderColor: '#000',
    borderWidth: 1,
    padding: 4,
    fontSize: 15,
    marginBottom: 15,
    marginHorizontal: 5,
  },
  ContainerButton: {
    flex: 1,
    flexDirection: 'row',
  },
  ContainerButton2: {
    flex: 1,
  },
  ContainerButtonButtom: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  imputButton: {
    backgroundColor: Colors.contra,
    paddingVertical: 2,
    paddingHorizontal: 15,
    borderRadius: 8,
    marginBottom: 5,
    marginRight: 10,
  },
  loginInvalid1: {
    width: 300,
    backgroundColor: 'red',
    paddingHorizontal: 5,
    paddingVertical: 5,
    marginBottom: 10,
  },
  sucess1: {
    width: 300,
    backgroundColor: 'green',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  loginInvalid2: {
    width: '80%',
    backgroundColor: 'red',
    paddingHorizontal: 5,
    paddingVertical: 5,
    marginBottom: 10,
  },
  sucess: {
    width: '80%',
    backgroundColor: 'green',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  textOnRecover: {
    fontSize: 12,
  },
  loading: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.2)',
    justifyContent: 'center',
  },
  deleteClient: {
    position: 'absolute',
    bottom: -5,
    right: -22,
    width: 30,
    height: 30,
  },
});
export default Client;
