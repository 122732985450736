/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Text, View, StyleSheet} from 'react-native';

import Colors from '../styles/colors';

const LoadingFloor = () => {
  const loading = ['●•••', '•●••', '••●•', '•••●', '••●•', '•●••', '●•••'];
  let speed = 0;
  let index = 0;
  const [LoadView, setLoadView] = useState(loading[0]);

  function load() {
    setLoadView(loading[index]);
    if (index < loading.length - 1) {
      speed++;
      if (speed === 8) {
        index++;
        speed = 0;
      }
    } else {
      index = 0;
    }
    requestAnimationFrame(() => load());
  }
  useEffect(() => {
    load();
  }, []);

  return (
    <View>
      <Text style={styles.text}>{LoadView}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    color: Colors.grey,
  },
});
export default LoadingFloor;
