const Colors = {
  white: '#ffffff',
  black: '#000000',
  turquese: '#1abc9c',
  turqueseDark: '#16a085',
  green: '#2ecc71',
  greenDark: '#27ae60',
  blue: '#3498db',
  blueDark: '#2980b9',
  violet: '#9b59b6',
  violetDark: '#8e44ad',
  yellow: '#f1c40f',
  yellowDark: '#f39c12',
  orange: '#e67e22',
  orangeDark: '#d35400',
  red: '#ff0000',
  redDark: '#c0392b',
  champagne: '#ecf0f1',
  champagneDark: '#bdc3c7',
  metal: '#95a5a6',
  metalDark: '#7f8c8d',
  asphalt: '#34495e',
  asphaltDark: '#2c3e50',
  carbon: '#555459',
  carbonDark: '#3b3a3e',

  azulclado: '#A8C2F0',
  list: '#FBEBAD',
  listItem: '#F2E5B1',
  primary: '#3498db',
  secondary: '#2ecc71',
  danger: '#f37272',
  light: '#ecf0f1',
  dark: '#34495e',
  grey: '#D9DCDE',
  backloung: '#0000ff',
  background: '#D9FFFF',
  checkin: '#3AE63A',
  placeholder: '#9B8E62',
  buscar: '#B8FFB8',
  cadastrar: '#ABFFFF',
  dateEntry: '#D9FFFF',
  dataReady: '#C7FFC7',
  dataReturn: '#FFB2B2',
  dataExit: '#C8EAF9',
  servicePlus: '#8CFF8C',
  greenBlock: '#94fc74',
  blueBlock: '#99DAFF',

  loged: '#74DBDB',
  contra: '#394871',
  faixa: '#00FFFF',
  itens: '#D9FFFF',
};

export default Colors;
