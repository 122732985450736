/* eslint-disable react-native/no-inline-styles */
import React, {useState} from 'react';
import {
  ScrollView,
  Text,
  View,
  ActivityIndicator,
  StyleSheet,
  CheckBox,
  TextInput,
  TouchableOpacity,
  Modal,
} from 'react-native';

import Colors from '../../styles/colors';

import Homepage from '../../services/homepage';

const Login = ({dimension, setMain, setLogin}) => {
  const [noPassword, setNoPassword] = useState(false);
  const [sucessRestair, setSucessRestair] = useState(false);
  const [msgSucessRestair, setMsgSucessRestair] = useState('');
  const [valueLogin, setValueLogin] = useState('');
  const [valuePassword, setValuePassword] = useState('');
  const [valueEmail, setValueEmail] = useState('');
  const [errorMensage, setErrorMensage] = useState('');
  const [onRecoverPassword, setOnRecoverPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [onLoading, setOnLoading] = useState(false);

  const OnLogin = () => {
    fetch(Homepage + 'login.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Login: valueLogin,
        Password: valuePassword,
        OnCookie: checked,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.status) {
          //Login OFF
          setNoPassword(!data.status);
          setErrorMensage(data.mesage);
        } else {
          //Login ON
          setChecked(!data.status);
          setLogin(!data.status);
          setMain(data.status);
          setValueLogin('');
          setValuePassword('');
          setNoPassword(!data.status);
          setErrorMensage('');
        }
        setOnLoading(false);
      })
      .catch((error) => {
        setNoPassword(true);
        setErrorMensage('Ocorreu um erro no banco de dados');
        console.error('Ocorreu um erro no banco de dados', error);
        setOnLoading(false);
      });
  };

  const OnRecover = () => {
    fetch(Homepage + 'forgetPassword.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: valueEmail,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setNoPassword(false);
          setSucessRestair(true);
          setMsgSucessRestair(data.mesage);
        } else {
          setSucessRestair(false);
          setNoPassword(true);
          setErrorMensage(data.mesage);
        }
        setOnLoading(false);
      })
      .catch((error) => {
        setNoPassword(true);
        setErrorMensage('Ocorreu um erro no banco de dados');
        console.error('Ocorreu um erro no banco de dados', error);
        setOnLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      {onLoading && (
        <Modal transparent={true}>
          <View style={{height: dimension.width > 500 ? 100 : '15vw'}} />
          <View style={styles.loading}>
            <ActivityIndicator size="large" color="#fff" />
          </View>
        </Modal>
      )}
      <View style={styles.preScroll} />
      <ScrollView>
        <View style={styles.scrollView}>
          <View style={styles.scrollLogin}>
            <Text style={styles.titleLogin}>ACESSO RESTRITO</Text>
            <View style={styles.login}>
              {noPassword && (
                <View
                  style={
                    dimension.width > 600
                      ? styles.loginInvalid1
                      : styles.loginInvalid2
                  }>
                  <Text style={{color: 'white', fontSize: 12}}>
                    {errorMensage}
                  </Text>
                </View>
              )}
              {sucessRestair && (
                <View
                  style={
                    dimension.width > 500
                      ? styles.sucessRestair1
                      : styles.sucessRestair2
                  }>
                  <Text style={{color: 'white', fontSize: 12}}>
                    {msgSucessRestair}
                  </Text>
                </View>
              )}
              <Text style={styles.textLogin}>LOGIN/E-MAIL</Text>
              <TextInput
                onChangeText={(text) => setValueLogin(text)}
                value={valueLogin}
                style={styles.imputLogin}
              />
              <Text style={styles.textSenha}>SENHA</Text>
              <TextInput
                onChangeText={(text) => setValuePassword(text)}
                value={valuePassword}
                style={styles.imputSenha}
                secureTextEntry={true}
              />
              <View style={styles.viewCheckbox}>
                <CheckBox
                  value={checked}
                  onValueChange={setChecked}
                  style={styles.checkbox}
                />
                <Text> manter-me conectado</Text>
              </View>
              <TouchableOpacity
                style={styles.imputButton}
                onPress={() => {
                  if (valueLogin && valuePassword) {
                    setOnLoading(true);
                    OnLogin();
                  } else {
                    setNoPassword(true);
                    setErrorMensage('Você precisa preencher os campos');
                  }
                }}>
                <Text style={{color: 'white', fontSize: 18}}>OK</Text>
              </TouchableOpacity>
              <Text style={styles.textOnRecover}>
                Para recuperar a senha clique{' '}
                <TouchableOpacity
                  onPress={() => {
                    setOnRecoverPassword(true);
                  }}>
                  <Text style={styles.textOnRecoverLink}>aqui</Text>
                </TouchableOpacity>
              </Text>
              {onRecoverPassword && (
                <Modal transparent={true}>
                  <View
                    style={{height: dimension.width > 500 ? 100 : '15vw'}}
                  />
                  <View style={styles.modalRecover}>
                    <View style={styles.modalRecoverPrompt}>
                      <Text style={styles.modalRecoverTitle}>
                        RECUPERAÇÃO DE SENHA
                      </Text>
                      <Text style={styles.modalRecoverText}>
                        Para recuperção de senha digite seu e-mail
                      </Text>
                      <TextInput
                        onChangeText={(text) => setValueEmail(text)}
                        style={styles.imputEmail}
                        value={valueEmail}
                        placeholder="Digite seu e-mail"
                      />
                      <View style={styles.viewButtonRecover}>
                        <TouchableOpacity
                          style={styles.imputButtonRecover}
                          onPress={() => {
                            setOnRecoverPassword(false);
                          }}>
                          <Text style={{color: 'white', fontSize: 12}}>
                            Cancelar
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={styles.imputButtonRecover}
                          onPress={() => {
                            if (valueEmail) {
                              setOnLoading(true);
                              OnRecover();
                              setOnRecoverPassword(false);
                              setValueEmail('');
                            } else {
                              setNoPassword(true);
                              setErrorMensage(
                                'Você precisa preencher um email',
                              );
                            }
                          }}>
                          <Text style={{color: 'white', fontSize: 12}}>OK</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </Modal>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.preScroll} />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 500,
  },
  preScroll: {
    flex: 1,
  },
  scrollView: {
    alignItems: 'center',
  },
  scrollLogin: {
    padding: 20,
    textAlign: 'center',
  },
  login: {
    marginTop: 10,
    alignItems: 'center',
    backgroundColor: Colors.white,
    paddingHorizontal: 30,
    paddingVertical: 15,
    borderRadius: 20,
  },
  titleLogin: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 20,
    color: Colors.contra,
  },
  imputLogin: {
    borderColor: '#000',
    borderWidth: 1,
    padding: 4,
    fontSize: 15,
    marginBottom: 10,
  },
  textLogin: {
    fontWeight: 'bold',
    marginBottom: 10,
    fontSize: 18,
  },
  textSenha: {
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 18,
  },
  imputSenha: {
    borderColor: '#000',
    borderWidth: 1,
    padding: 4,
    fontSize: 15,
    marginBottom: 15,
  },
  imputButton: {
    backgroundColor: Colors.contra,
    paddingVertical: 2,
    paddingHorizontal: 15,
    borderRadius: 8,
    marginBottom: 5,
  },
  loginInvalid1: {
    width: 220,
    backgroundColor: 'red',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  sucessRestair1: {
    width: 220,
    backgroundColor: 'green',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  loginInvalid2: {
    width: '80%',
    backgroundColor: 'red',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  sucessRestair2: {
    width: '80%',
    backgroundColor: 'green',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  textOnRecover: {
    fontSize: 12,
  },
  textOnRecoverLink: {
    fontSize: 12,
    color: 'blue',
  },
  modalRecover: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  modalRecoverPrompt: {
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
  },
  modalRecoverTitle: {
    fontWeight: 'bold',
  },
  modalRecoverText: {
    fontSize: 11,
    marginBottom: 10,
  },
  imputEmail: {
    padding: 3,
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 3,
  },
  viewButtonRecover: {
    flexDirection: 'row',
    alignContent: 'center',
    marginTop: 10,
  },
  imputButtonRecover: {
    backgroundColor: Colors.contra,
    paddingVertical: 2,
    paddingHorizontal: 15,
    borderRadius: 8,
    margin: 5,
  },
  viewCheckbox: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  loading: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.2)',
    justifyContent: 'center',
  },
});
export default Login;
