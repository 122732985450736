/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useState} from 'react';
import {
  View,
  StyleSheet,
  Image,
  Dimensions,
  Modal,
  ActivityIndicator,
  Text,
} from 'react-native';

import Login from './pages/Login';
import Colors from './styles/colors';

import Homepage from './services/homepage';
import Main from './pages/Main';

const window = {x: {window: Dimensions.get('window')}};

const App = () => {
  const [main, setMain] = useState(false);
  const [login, setLogin] = useState(false);
  const [dimensions, setDimensions] = useState(window);
  const [onLoading, setOnLoading] = useState(true);
  const [noPassword, setNoPassword] = useState(false);
  const [errorMensage, setErrorMensage] = useState('');

  const onChange = (x) => {
    setDimensions({x});
  };

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  });

  useEffect(() => {
    OnStart();
  }, []);

  const OnStart = () => {
    fetch(Homepage + 'start.php')
      .then((response) => response.json())
      .then((data) => {
        setLogin(!data.statusLogin);
        setMain(data.statusLogin);
        if (data.recover) {
          setLogin(false);
          setMain(true);
        }
        setOnLoading(false);
      })
      .catch((error) => {
        setNoPassword(true);
        setErrorMensage('erro ao buscar dados internos');
        console.error('erro ao buscar dados internos', error);
        setOnLoading(false);
      });
  };
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.header,
          {height: dimensions.x.window.width > 500 ? 100 : '15vw'},
        ]}>
        <Image
          accessibilityLabel="React logo"
          source={{
            uri: 'https://climserver.tecmaster.cloud/excellence/images/svg/logo.svg',
          }}
          style={styles.logo}
          resizeMode={'contain'}
        />
      </View>
      {onLoading && (
        <Modal transparent={true}>
          <View
            style={{height: dimensions.x.window.width > 500 ? 100 : '15vw'}}
          />
          <View style={styles.loading}>
            <ActivityIndicator size="large" color="#fff" />
          </View>
        </Modal>
      )}
      {noPassword && !login && !main && (
        <View style={{flex: 1, justifyContent: 'center'}}>
          <View
            style={[
              styles.loginInvalid,
              {width: 300, borderRadius: 10, alignSelf: 'center', padding: 10},
            ]}>
            <Text
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              ATENÇÃO {'\n\n'}
              {errorMensage.toLocaleUpperCase()}
            </Text>
          </View>
        </View>
      )}
      {login && (
        <Login
          setMain={setMain}
          setLogin={setLogin}
          dimension={dimensions.x.window}
        />
      )}
      {main && (
        <Main
          dimension={dimensions.x.window}
          Loading={setOnLoading}
          LogedOff={(logout) => {
            setMain(!logout);
            setLogin(logout);
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  logo: {
    height: '90%',
    resizeMode: 'stretch',
    marginHorizontal: 30,
  },
  header: {
    justifyContent: 'center',
    borderBottomWidth: 8,
    borderBottomColor: Colors.faixa,
  },
  loading: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.2)',
    justifyContent: 'center',
  },
  loginInvalid: {
    backgroundColor: 'red',
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginBottom: 10,
  },
});
export default App;
