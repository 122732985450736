/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  Image,
  FlatList,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Modal,
  TextInput,
  ActivityIndicator,
  Linking,
} from 'react-native';

import Homepage from '../../services/homepage';
import Client from './client';
import Colors from '../../styles/colors';
import LoadingFloor from '../../services/loadingFloor';
import Confirm from '../../services/confirm';

const Main = ({LogedOff = false, Loading, dimension}) => {
  const [nameLoged, setNameLoged] = useState('');
  const [onLoading, setOnLoading] = useState(false);
  const [erro, setErro] = useState(false);
  const [errorMensage, setErrorMensage] = useState('');
  const [prescription, setPrescription] = useState([]);
  const [prescriptionRecept, setPrescriptionRecept] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(false);

  const [searshPacient, setSearshPacient] = useState('');
  const [searshDentist, setSearshDentist] = useState('');
  const [searshDate, setSearshDate] = useState('');
  const [notSearsh, setNotSearsh] = useState('');

  const [recover, setRecover] = useState(false);
  const [recoverClient, setRecoverClient] = useState('');
  const [change, setChange] = useState(0);

  const [changeDeleted, setChangeDeleted] = useState(0);

  const [client, setClient] = useState(false);
  const [end, setEnd] = useState(30);
  const [activeLoadingFloor, setActiveLoadingFloor] = useState(false);

  const [alterCliente, setAlterCliente] = useState(false);

  useEffect(() => {
    OnStart();
  }, [change]);

  useEffect(() => {
    Loading(true);
    reset();
    GetFullPrescription();
  }, [changeDeleted]);

  useEffect(() => {
    if (end > 39) {
      GetFullPrescription();
    }
  }, [end]);

  useEffect(() => {
    if (searshDate || searshDentist || searshPacient) {
      receptFilter();
    }
  }, [searshDate, searshDentist, searshPacient]);

  const reset = () => {
    setPrescription([]);
    setErro(false);
    setErrorMensage('');
  };

  const OnStart = () => {
    fetch(Homepage + 'start.php')
      .then((response) => response.json())
      .then((data) => {
        setNameLoged(data.name);

        if (data.recover) {
          setOnLoading(true);
          setRecoverClient(data.recoverId);
          setClient(true);
          setRecover(true);
        }
      })
      .catch((error) => {
        console.error('erro ao buscar dados internos', error);
        OnLogout();
      });
  };
  const GetFullPrescription = () => {
    fetch(Homepage + 'getFullPrescription.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        End: end,
        SearshPacient: null,
        SearshDentist: null,
        SearshDate: null,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.notSearsh) {
          setNotSearsh(data.notSearsh);
          Loading(false);
          setActiveLoadingFloor(false);
        } else {
          setNotSearsh('');
          setPrescription(data);
          Loading(false);
          setActiveLoadingFloor(false);
        }
      })
      .catch((error) => {
        setErro(true);
        setErrorMensage('erro ao buscar dados internos');
        console.error('erro ao buscar dados internos', error);
        Loading(false);
        setActiveLoadingFloor(false);
      });
  };
  const receptFilter = () => {
    fetch(Homepage + 'getFullPrescription.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        End: null,
        SearshPacient: searshPacient ? searshPacient : null,
        SearshDentist: searshDentist ? searshDentist : null,
        SearshDate: searshDate ? searshDate : null,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setNotSearsh('');
          setErro(true);
          setErrorMensage(data.error);
          console.error(data.error);
          Loading(false);
          setActiveLoadingFloor(false);
        } else if (data.notSearsh) {
          setNotSearsh(data.notSearsh);
          Loading(false);
          setActiveLoadingFloor(false);
        } else {
          setNotSearsh('');
          setPrescriptionRecept(data);
          Loading(false);
          setActiveLoadingFloor(false);
        }
      })
      .catch((error) => {
        setErro(true);
        setErrorMensage('erro ao buscar dados internos');
        console.error('erro ao buscar dados internos', error);
        Loading(false);
        setActiveLoadingFloor(false);
      });
  };

  const onDelete = (id) => {
    fetch(Homepage + 'deletePrescription.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Id: id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.delete) {
          setChangeDeleted(changeDeleted + 1);
          console.warn('Usuário deletado com sucesso!');
        } else {
          setErro(true);
          setErrorMensage('Erro ao deletar Usuário');
          console.warn('Erro ao deletar Usuário');
          Loading(false);
        }
      })
      .catch((error) => {
        console.error(
          'erro ao buscar dados internos para deletar o usuário',
          error,
        );
        setErro(true);
        setErrorMensage('erro ao buscar dados internos para deletar o usuário');
        Loading(false);
      });
  };

  const OnLogout = () => {
    fetch(Homepage + 'logout.php')
      .then((response) => response.json())
      .then((data) => {
        LogedOff(data.logout);
        setOnLoading(false);
        Loading(false);
      })
      .catch((error) => {
        console.error('erro ao buscar dados internos', error);
        setOnLoading(false);
        Loading(false);
      });
  };

  const Goback = (go) => {
    if (go === 'client') {
      setClient(false);
    }
    setAlterCliente(false);
    setOnLoading(false);
  };

  const OnChange = () => {
    setChange(change + 1);
  };

  const responsive = dimension.width > 800 ? styles.desktop : styles.smart;
  return (
    <View style={styles.master}>
      <View style={styles.menuLoged}>
        <TouchableOpacity
          onPress={() => {
            Loading(true);
            OnLogout();
          }}
          style={styles.buttonLogout}>
          <Text style={styles.textLogout}>Logout</Text>
          <Image
            accessibilityLabel="React logo"
            source={{
              uri: 'https://climserver.tecmaster.cloud/excellence/images/svg/logout.svg',
            }}
            style={styles.logout}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            if (alterCliente || !client) {
              setOnLoading(true);
            }
            setClient(true);
            setAlterCliente(false);
          }}
          style={styles.ContainerclientPlus}>
          <Image
            accessibilityLabel="React logo"
            source={{
              uri: 'https://climserver.tecmaster.cloud/excellence/images/svg/clientplus.svg',
            }}
            style={styles.clientPlus}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <Text style={styles.loged}>
          Seja bem vindo{'   '}
          <TouchableOpacity
            onPress={() => {
              if (!alterCliente) {
                setOnLoading(true);
              }
              setClient(true);
              setAlterCliente(true);
            }}>
            <Text style={{color: 'white'}}>{nameLoged}</Text>
          </TouchableOpacity>
        </Text>
      </View>
      {onLoading && (
        <Modal transparent={true}>
          <View style={{height: dimension.width > 500 ? 100 : '15vw'}} />
          <View style={styles.loading}>
            <ActivityIndicator size="large" color="#fff" />
          </View>
        </Modal>
      )}
      <View style={styles.preScroll} />
      <ScrollView>
        <View style={styles.container}>
          {!client && (
            <View>
              <View
                style={dimension.width > 800 ? styles.prompt1 : styles.prompt2}>
                <View style={[styles.headerTitle, {flex: 1.3}]}>
                  <Text
                    style={
                      dimension.width > 800 ? styles.title1 : styles.title2
                    }>
                    SISTEMAS DE RECEITUÁRIO DIGITAL
                  </Text>
                  <View
                    style={{
                      borderBottomWidth: 1.5,
                      width: dimension.width > 800 ? '40%' : '100%',
                      alignSelf: 'center',
                    }}
                  />
                </View>
                {erro && (
                  <View
                    style={
                      dimension.width > 800
                        ? styles.loginInvalid1
                        : styles.loginInvalid2
                    }>
                    <Text style={{color: 'white', fontSize: 12}}>
                      {errorMensage}
                    </Text>
                  </View>
                )}
                <View
                  style={[
                    dimension.width > 800
                      ? {flex: 1.5}
                      : {padding: 10, borderRadius: 10},
                    {
                      marginHorizontal: 10,
                      backgroundColor: Colors.loged,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  ]}>
                  <View style={responsive}>
                    <View style={styles.desktop}>
                      <Image
                        accessibilityLabel="React logo"
                        source={{
                          uri:
                            'https://climserver.tecmaster.cloud/excellence/images/svg/buscar.svg',
                        }}
                        style={{marginRight: 20, width: 30, height: 30}}
                        resizeMode="contain"
                      />
                      <Text
                        style={{color: 'white', fontSize: 18, marginRight: 40}}>
                        BUSCAR
                      </Text>
                    </View>
                    <View style={styles.desktop}>
                      <Image
                        accessibilityLabel="React logo"
                        source={{
                          uri:
                            'https://climserver.tecmaster.cloud/excellence/images/svg/paciente-busca.svg',
                        }}
                        style={{marginRight: 20, width: 30, height: 30}}
                        resizeMode="contain"
                      />
                      <TextInput
                        style={styles.input}
                        placeholder="BUSCAR POR PACIENTE"
                        value={searshPacient}
                        onChangeText={(text) => {
                          const regex = /^[a-zA-Z ]*$/;
                          if (regex.test(text)) {
                            setActiveLoadingFloor(true);
                            setSearshDentist('');
                            setSearshDate('');
                            setSearshPacient(text.toUpperCase());
                          }
                        }}
                      />
                    </View>
                    <View style={styles.desktop}>
                      <Image
                        accessibilityLabel="React logo"
                        source={{
                          uri:
                            'https://climserver.tecmaster.cloud/excellence/images/svg/dentista-busca.svg',
                        }}
                        style={{marginRight: 20, width: 30, height: 30}}
                        resizeMode="contain"
                      />
                      <TextInput
                        style={styles.input}
                        placeholder="BUSCAR POR DENTISTA"
                        value={searshDentist}
                        onChangeText={(text) => {
                          const regex = /^[a-zA-Z ]*$/;
                          if (regex.test(text)) {
                            setActiveLoadingFloor(true);
                            setSearshPacient('');
                            setSearshDate('');
                            setSearshDentist(text.toUpperCase());
                          }
                        }}
                      />
                    </View>
                    <View style={styles.desktop}>
                      <Image
                        accessibilityLabel="React logo"
                        source={{
                          uri:
                            'https://climserver.tecmaster.cloud/excellence/images/svg/calendario-busca2.svg',
                        }}
                        style={{marginRight: 20, width: 30, height: 30}}
                        resizeMode="contain"
                      />
                      <TextInput
                        style={styles.input}
                        placeholder="BUSCAR POR DATA"
                        value={searshDate}
                        onChangeText={(text) => {
                          const regex = /[0-3]/;
                          const regex2 = /[0-1/]$/;
                          const regex3 = /[0-9/]$/;
                          const dias =
                            text.length > 0 && text.length < 3
                              ? text.substr(0, 2)
                              : null;
                          const meses =
                            text.length > 3 && text.length < 6
                              ? text.substr(3, 5)
                              : null;

                          if (
                            (text.length < 3 &&
                              regex.test(text) &&
                              dias <= 31) ||
                            (text.length > 2 &&
                              text.length < 5 &&
                              regex2.test(text)) ||
                            (text.length > 4 &&
                              text.length < 6 &&
                              regex3.test(text) &&
                              meses <= 12) ||
                            text.length === 0 ||
                            text.length > 5
                          ) {
                            if (text.length < 7) {
                              setActiveLoadingFloor(true);
                              setSearshPacient('');
                              setSearshDentist('');
                              setSearshDate(
                                text.replace(/(\d{2})(\d)/, '$1/$2'),
                              );
                            } else if (text.length < 11) {
                              setActiveLoadingFloor(true);
                              setSearshPacient('');
                              setSearshDentist('');
                              setSearshDate(text);
                            }
                          }
                        }}
                      />
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flex: 12,
                    marginHorizontal: 30,
                    justifyContent: 'center',
                  }}>
                  {notSearsh && (
                    <Text
                      style={{
                        alignSelf: 'center',
                        marginVertical: 40,
                        color: Colors.green,
                      }}>
                      {notSearsh}
                    </Text>
                  )}
                  {prescription.length === 0 && !notSearsh && (
                    <Text
                      style={{
                        alignSelf: 'center',
                        marginVertical: 40,
                        color: Colors.green,
                      }}>
                      Não há receituários cadastrados
                    </Text>
                  )}
                  {prescription.length !== 0 && !notSearsh && (
                    <FlatList
                      data={
                        searshPacient || searshDentist || searshDate
                          ? prescriptionRecept
                          : prescription
                      }
                      style={styles.flatList}
                      onEndReached={() => {
                        if (
                          prescription.length > 10 ||
                          prescriptionRecept > 10
                        ) {
                          setActiveLoadingFloor(true);
                          setEnd(end + 20);
                        }
                      }}
                      onEndReachedThreshold={0.4}
                      keyExtractor={(item) => item[0]}
                      renderItem={({item}) => (
                        <View
                          style={{
                            marginTop: 10,
                            flex: 1,
                            paddingHorizontal: 30,
                            paddingVertical: 5,
                            flexDirection: 'row',
                            backgroundColor: Colors.itens,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 5,
                          }}>
                          <View
                            style={{
                              flexDirection:
                                dimension.width > 800 ? 'row' : 'column',
                              flex: 1,
                            }}>
                            <View
                              style={{
                                flexDirection:
                                  dimension.width > 800 ? 'row' : 'column',
                                flex: 1,
                              }}>
                              <View
                                style={[
                                  {
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  },
                                  dimension.width < 800 && {
                                    flex: 1,
                                    marginLeft: 20,
                                  },
                                ]}>
                                <Image
                                  accessibilityLabel="React logo"
                                  source={{
                                    uri:
                                      'https://climserver.tecmaster.cloud/excellence/images/svg/paciente.svg',
                                  }}
                                  style={{
                                    marginRight: 10,
                                    width: 30,
                                    height: 30,
                                  }}
                                  resizeMode="contain"
                                />
                                <Text style={{marginRight: 20}}>{item[2]}</Text>
                              </View>
                              <View
                                style={[
                                  {
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  },
                                  dimension.width < 800 && {
                                    flex: 1,
                                    marginLeft: 20,
                                  },
                                ]}>
                                <Image
                                  accessibilityLabel="React logo"
                                  source={{
                                    uri:
                                      'https://climserver.tecmaster.cloud/excellence/images/svg/dentista.svg',
                                  }}
                                  style={{
                                    marginRight: 10,
                                    width: 30,
                                    height: 30,
                                  }}
                                  resizeMode="contain"
                                />
                                <Text style={{marginRight: 20}}>{item[3]}</Text>
                              </View>
                              <View
                                style={{
                                  flexDirection:
                                    dimension.width > 800 ? 'row' : 'column',
                                  flex: 1,
                                }}>
                                <View
                                  style={[
                                    {
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    },
                                    dimension.width < 800 && {
                                      flex: 1,
                                      marginLeft: 20,
                                    },
                                  ]}>
                                  <Image
                                    accessibilityLabel="React logo"
                                    source={{
                                      uri:
                                        'https://climserver.tecmaster.cloud/excellence/images/svg/calendario.svg',
                                    }}
                                    style={{
                                      marginRight: 10,
                                      width: 30,
                                      height: 30,
                                    }}
                                    resizeMode="contain"
                                  />
                                  <Text style={{marginRight: 20}}>
                                    {item[1]}
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    {
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    },
                                    dimension.width < 800 && {
                                      flex: 1,
                                      marginLeft: 20,
                                    },
                                  ]}>
                                  <Image
                                    accessibilityLabel="React logo"
                                    source={{
                                      uri:
                                        'https://climserver.tecmaster.cloud/excellence/images/svg/type-full.svg',
                                    }}
                                    style={{
                                      marginRight: 10,
                                      width: 30,
                                      height: 30,
                                    }}
                                    resizeMode="contain"
                                  />
                                  <Text style={{marginRight: 20}}>
                                    {item[4].toUpperCase()}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.desktop,
                                dimension.width < 800 && {marginLeft: 20},
                              ]}>
                              <TouchableOpacity
                                onPress={() => {
                                  Linking.openURL(
                                    'services/print.php?id=' + item[0],
                                  );
                                }}
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}>
                                <Image
                                  accessibilityLabel="React logo"
                                  source={{
                                    uri:
                                      'https://climserver.tecmaster.cloud/excellence/images/svg/imprimir.svg',
                                  }}
                                  style={{
                                    marginRight: 10,
                                    width: 30,
                                    height: 30,
                                  }}
                                  resizeMode="contain"
                                />
                                <Text style={{marginRight: 20}}>IMPRIMIR</Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                onPress={() => {
                                  Loading(true);
                                  setIdDelete(item[0]);
                                  setConfirmDelete(true);
                                }}>
                                <Image
                                  accessibilityLabel="React logo"
                                  source={{
                                    uri:
                                      'https://climserver.tecmaster.cloud/excellence/images/svg/deletar.svg',
                                  }}
                                  style={{
                                    marginRight: 10,
                                    width: 30,
                                    height: 30,
                                  }}
                                  resizeMode="contain"
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}
                    />
                  )}
                </View>
                {activeLoadingFloor && (
                  <View style={{alignSelf: 'center'}}>
                    <LoadingFloor />{' '}
                  </View>
                )}
              </View>
            </View>
          )}

          {client && (
            <Client
              Loading={setOnLoading}
              Goback={Goback}
              dimension={dimension}
              recover={recover}
              recoverClient={recoverClient}
              recoverOff={setRecover}
              alterCliente={alterCliente}
              change={OnChange}
              OnLogout={OnLogout}
              setAlterCliente={setAlterCliente}
            />
          )}
          {confirmDelete && (
            <Confirm
              dimension={dimension}
              idDelete={idDelete}
              Loading={Loading}
              msg="Tem certeza que deseja apagar esse RECEITUÁRIO? Cuidado Essa medida é irreversível!"
              Goback={setConfirmDelete}
              OnFunction={onDelete}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  master: {
    flex: 1,
  },
  lojaLoged: {
    fontWeight: 'bold',
    color: Colors.azulclado,
  },
  loged: {
    fontWeight: 'bold',
    marginRight: 105,
    color: Colors.white,
  },
  menuLoged: {
    flexDirection: 'row-reverse',
    backgroundColor: Colors.loged,
    padding: 5,
  },
  buttonLogout: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    width: 30,
    marginRight: 70,
  },
  loginInvalid1: {
    width: '90%',
    backgroundColor: 'red',
    paddingHorizontal: 5,
    alignSelf: 'center',
    alignItems: 'center',
    paddingVertical: 5,
    marginBottom: 10,
  },
  loginInvalid2: {
    marginTop: 30,
    width: '80%',
    backgroundColor: 'red',
    paddingHorizontal: 5,
    paddingVertical: 5,
    marginBottom: 10,
    alignSelf: 'center',
    textAlign: 'center',
  },
  logout: {
    width: 20,
    height: 20,
  },
  clientPlus: {
    width: 20,
    height: 20,
  },
  ContainerclientPlus: {
    marginRight: -90,
  },
  textLogout: {
    fontSize: 10,
    marginLeft: 5,
    color: Colors.white,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  headerTitle: {
    marginTop: 10,
    marginHorizontal: 20,
  },
  title1: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    color: Colors.black,
  },
  title2: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    color: Colors.black,
  },
  prompt1: {
    width: 1300,
    height: 450,
    justifyContent: 'center',
    alignSelf: 'center',
    backgroundColor: Colors.faixa,
    borderRadius: 20,
  },
  prompt2: {
    width: '100%',
    height: '90%',
    justifyContent: 'center',
    paddingVertical: '3%',
    alignSelf: 'center',
    backgroundColor: Colors.faixa,
    borderRadius: 10,
    marginTop: 20,
  },
  menu1: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '50%',
    width: '100%',
  },
  menu2: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '50%',
    width: '60%',
  },
  menu3: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '70%',
    width: '100%',
  },
  subTitle1: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 20,
    marginHorizontal: 20,
    textAlign: 'center',
  },
  subTitle2: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 20,
    marginHorizontal: 20,
    textAlign: 'center',
  },
  subTitle3: {
    fontWeight: 'bold',
    marginBottom: 20,
    marginHorizontal: 20,
    textAlign: 'center',
  },
  button1: {
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 10,
    marginHorizontal: 10,
  },
  button2: {
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 10,
    marginHorizontal: 10,
  },
  button3: {
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 10,
    marginHorizontal: 20,
  },
  logo1: {
    width: 80,
    height: '60%',
    marginLeft: 20,
  },
  logo2: {
    width: 80,
    height: '50%',
    marginLeft: 10,
  },
  logo3: {
    width: 150,
    height: '60%',
    marginLeft: '8%',
  },
  textLogo1: {
    fontSize: 10,
    fontWeight: 'bold',
    marginTop: 5,
  },
  textLogo2: {
    fontSize: 10,
    fontWeight: 'bold',
    marginTop: 5,
  },
  textLogo3: {
    fontSize: 10,
    fontWeight: 'bold',
    marginTop: 5,
  },
  loading: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.2)',
    justifyContent: 'center',
  },
  input: {
    borderRadius: 4,
    textAlign: 'center',
    fontSize: 14,
    height: 25,
    width: 200,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'black',
    marginRight: 20,
  },
  smart: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: -20,
  },
  desktop: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  preScroll: {
    flex: 1,
  },
});
export default Main;
